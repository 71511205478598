input[type="color"] {
	-webkit-appearance: none;
	appearance: none;
	border: none;
	width: 32px;
	height: 32px;
}

input[type="color"]::-webkit-color-swatch-wrapper {
	padding: 0;
}

input[type="color"]::-webkit-color-swatch {
	border: none;
  border-radius: 2px;
}
