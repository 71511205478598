@tailwind base;
@tailwind components;
@tailwind utilities;

button:focus {
    outline: 2px solid blue;
    @apply focus:outline-none focus:ring focus:ring-blue-500;
}

::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 12px;
}

*:hover::-webkit-scrollbar {
    -webkit-appearance: none;
}

::-webkit-scrollbar-track {
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: rgba(51, 51, 51, 0.8);
    border: 2px solid transparent;
    background-clip: padding-box;
}

::-webkit-scrollbar-thumb:hover {
    border: 0;
}

body { 
    overflow: hidden;
}

:root {
    font-size: 12pt;
    line-height: 1.15em;
}